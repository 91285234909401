@use "./design_tokens" as *;
@use "./_utilities" as *;

$screen-sm-max: 834px;
$screen-xl-min: 1440px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-sm-max}) and (max-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Medium  and extra large devices
@mixin mdxl {
  @media (min-width: #{$screen-sm-max}) {
    @content;
  }
}

// Font sizes
%h1-size {
  font-size: 2rem;

  @include mdxl {
    font-size: 2.5rem;
  }
}

%h2-size {
  font-size: 1.5rem;

  @include mdxl {
    font-size: 2rem;
  }
}

%h3-size {
  font-size: 1rem;

  @include mdxl {
    font-size: 1.5rem;
  }
}

%h4-size {
  font-size: 0.88rem;

  @include mdxl {
    font-size: 1rem;
  }
}

%text-size-sm {
  font-size: 0.88rem;
}

@mixin text-size-xl {
  font-size: 1rem;
}

%text-size {
  @extend %text-size-sm;

  @include mdxl {
    @include text-size-xl;
  }
}

%cta-size {
  font-size: 1rem;
}

%small-cta-size {
  font-size: 0.75rem;
  margin: em(3) 0 0 0;
}

%percentage {
  font-size: 0.88rem;
}

%score-size {
  font-size: 1.5rem;
}

// Font weights
%h1-weight {
  font-weight: 700;
}

%h2-weight {
  font-weight: 700;
}

%h3-weight {
  font-weight: 700;
}

%h4-weight {
  font-weight: 600;
}

%text-weight {
  font-weight: 400;
}

%strong-weight {
  font-weight: 700;
}

%strong-light-weight {
  font-weight: 600;
}

%cta-weight {
  font-weight: 700;
}

%bigger-lineheight {
  line-height: 150%;
}

%badge {
  background-color: $blue-200;
  width: fit-content;
  display: inline-block;
  padding: 0.25em 1em;
  margin: 0.5em 0.25em;
  border-radius: 1.5em;

  @include mdxl {
    padding: 0.5em 1.5em;
    margin: 0.5em 0.25em;
  }
}
