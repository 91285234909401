@use "~styles" as *;

.search-loader {
  transition: opacity 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    position: relative;
    display: inline-block;
    width: em(125);
    margin: em(24);
  }
}
