@use "~styles" as *;

.brand-search-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: em(24);

  &__cta {
    margin-bottom: em(24);

    @include mdxl {
      width: em(320) !important;
      max-width: 90%;
    }
  }
}
