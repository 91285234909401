@use "~styles" as *;

.arrow-link {
  border: none !important;
  padding: 0 !important;

  &__content {
    text-align: left;
  }
}
