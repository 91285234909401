@use "~styles" as *;

.header {
  background-color: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $blue-200;
  justify-content: center;

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex: 1;
    align-items: center;
    padding-left: 1em;
    padding-right: 2.5em;
    height: 3rem;

    @include mdxl {
      height: 5rem;
      padding-left: 3em;
      padding-right: 3em;
    }

    @include xl {
      padding-left: 8em;
      padding-right: 8em;
    }
  }

  &__logo {
    position: relative;
    justify-self: center;
    width: 8.94rem;

    @include mdxl {
      width: 14.75rem;
      align-self: center;
      justify-self: start;
    }
  }

  &__back-button {
    position: relative;
    justify-self: start;
    padding: 0;
    cursor: pointer;
    filter: $blue-500-filter;
    width: rem(9);

    @include mdxl {
      display: none;
    }

    &--hidden {
      display: block;
      width: em(20);
      cursor: default;

      @include mdxl {
        display: none;
      }
    }
  }

  &__search {
    display: none;

    @include mdxl {
      display: block;
      justify-self: center;
    }

    &.home {
      display: none;
      @include mdxl {
        display: block;
        visibility: hidden;
      }
    }
  }

  &__lang-selector {
    justify-self: end;
    color: $text-color;

    /* stylelint-disable-next-line selector-class-pattern */
    &.header__lang-selector--home {
      color: $white;
      filter: fill-color($white);
    }
  }
}
