@use "~styles" as *;

.cta-button {
  padding: em(12) em(24);
  border-radius: 8px;
  cursor: pointer;
  @extend %cta-weight;
  @extend %cta-size;

  width: fit-content;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }

  &__link {
    text-decoration: none;
  }

  &__image {
    position: relative;
    display: inline-block;
    min-width: em(24);
    min-height: em(24);

    &.left {
      margin-right: em(8);
    }

    &.right {
      margin-left: em(8);
    }

    &--primary {
      filter: $white-filter;
    }

    &--secondary {
      filter: $blue-600-filter;
    }

    &--tertiary {
      filter: $blue-500-filter;
    }
  }

  &.primary {
    background: $blue-500;
    color: $white;

    &--disabled {
      background: $grey-60;
    }
  }

  &.secondary {
    background: $green-100;
    color: $blue-600;

    &--disabled {
      background: $grey;
      color: $white;
    }
  }

  &.tertiary {
    background: transparent;
    color: $blue-500;
    border: solid 1px $blue-500;

    &--disabled {
      color: $grey;
      border: solid 1px $grey;
    }
  }
}
