@use "~styles" as *;

.search-bar-results {
  a {
    text-decoration: none;
  }

  &__no-result {
    height: em(56);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 em(16);
    padding-top: em(24);
  }

  &__hit {
    height: em(56);
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: em(21.32);
    border-bottom: 1px solid $blue-200;
    cursor: pointer;

    strong {
      &::first-letter {
        text-transform: capitalize;
      }
    }

    &:hover {
      strong {
        opacity: 0.6;
      }
    }
  }

  button:focus-visible {
    outline: 1px solid $blue-400;
  }

  &__hits {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
