@use "~styles" as *;

.loader {
  transition: opacity 0.2s linear;
  opacity: 0;

  &__container {
    min-height: em(100);
  }
}
