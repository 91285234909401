@use "~styles" as *;

.progress-bar {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;

  &__bar {
    height: em(8);
    width: 100%;
    background-color: $grey;
    border-radius: em(8);
    overflow: hidden;
  }

  &__progress {
    height: 100%;

    &--lowest {
      background-color: $score-lowest;
    }

    &--low {
      background-color: $score-low;
    }

    &--medium {
      background-color: $score-medium;
    }

    &--high {
      background-color: $score-high;
    }

    &--highest {
      background-color: $score-highest;
    }

    &--default {
      background-color: $blue-cyan;
    }
  }

  &__score {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    min-width: 3em;
    margin-left: em(16);
  }
}
