@use "./index.scss" as *;

html,
body {
  background-color: $background-color-darker;
  padding: 0;
  margin: 0;
  font-family: proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", sans-serif;
  scroll-behavior: smooth;
}

main {
  background-color: white;
  min-height: 50vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $text-color;
}

button {
  border: none;
  background: transparent;
}

img {
  max-inline-size: 100%;
  block-size: auto;
}

h1 {
  @extend %h1-size;
  @extend %h1-weight;

  margin: 0;
  margin-bottom: 1.5rem;

  @include mdxl {
    margin-bottom: 3.75rem;
  }
}

h2 {
  @extend %h2-size;
  @extend %h2-weight;

  margin-bottom: 1rem;
  margin-top: 1.5rem;

  @include mdxl {
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }
}

h3 {
  @extend %h3-size;
  @extend %h3-weight;

  margin-bottom: 1rem;
  margin-top: 1rem;

  @include mdxl {
    margin-top: 2rem;
  }
}

h4 {
  @extend %h4-size;
  @extend %h4-weight;
}

p {
  @extend %text-size;
  @extend %text-weight;

  white-space: pre-line;
}

a {
  color: inherit;
  text-decoration: underline;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

strong {
  @extend %strong-weight;
}

* {
  box-sizing: border-box;
}
