@use "~styles" as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 60%);
  backdrop-filter: blur(4px);
  z-index: 100;

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    @include mdxl {
      width: 60%;
      height: fit-content;
    }
  }

  &__content {
    background: $white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include mdxl {
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 3px 16px rgb(202 217 220 / 32%);
      border: solid 1px $blue-200;
      max-height: 80vh;
      padding-bottom: 1.5rem;
    }
  }

  &__header {
    position: relative;
    padding: 1.5em 1.5em 0;
    margin: 0;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    z-index: 1;
    cursor: pointer;
    margin-top: -0.25em;

    &:hover {
      opacity: 0.6;
    }
  }

  &__title {
    color: $text-color;
    margin: 0 2rem 1rem 0;
  }

  &__body {
    color: $text-color;
    overflow: auto;
    padding: 0 1.5rem 1.5rem;
    flex: 1;

    @include xl {
      padding-right: em(153);
      padding-left: em(153);
    }
  }
}
