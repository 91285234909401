.evaluation-link {
  &__modal {
    h2 {
      text-align: center;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__succeeded {
    margin: 0;
    font-weight: unset;
  }
}
