@use "~styles" as *;

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  height: em(30);
  width: fit-content;
  appearance: none;

  @include mdxl {
    height: em(60);
  }

  &__title {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &--white {
      color: $white;
    }
  }

  &__icon {
    position: relative;
    width: em(12);
    margin-left: em(4);
    margin-top: em(4);
    filter: $blue-600-filter;

    &--white {
      filter: $white-filter;
    }
  }

  &__content {
    position: absolute;
    top: calc(100%);
    right: -1em;
    width: calc(100%);
    min-width: 7.5em;
    display: none;
    border: 1px solid $blue-300;
    border-radius: 0.5em;
    box-shadow: 0 3px 16px rgba($blue-300, 0.64);
    background: $white;
    z-index: 10;
    overflow: hidden;

    @include mdxl {
      right: 0;
    }
  }

  &__option {
    height: 3.13em;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    &:hover {
      background-color: $blue-300;
    }
  }

  &__short-label {
    color: $text-color;
  }

  &:hover &__content {
    display: flex;
    flex-direction: column;
  }
}
