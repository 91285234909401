@use "~styles" as *;

.footer {
  display: flex;
  background-color: $background-color-darker;
  padding: 2.5em 1em;

  @include md {
    padding: 5em 1em;
  }
  @include xl {
    padding: 5em 1em;
  }

  a {
    text-decoration: none;
  }

  justify-content: center;

  &__section_container {
    display: flex;
    flex: 1;
    @include sm {
      flex-direction: column;
    }
    @include md {
      max-width: 50.19rem;
    }
    @include xl {
      max-width: 74rem;
    }
  }

  &__section {
    color: white;
    flex: 1;
    min-block-size: 8rem;
    text-align: center;

    @include sm {
      height: 100%;
      margin-bottom: 2.5em;
    }

    @include xl {
      border-right: 1px solid $footer-separator;

      &:last-of-type {
        border-right: none;
      }
    }

    &__title {
      margin-bottom: 1em;
    }

    &__footer_links {
      padding: 0;
    }
  }

  &__link {
    list-style-type: none;
    text-align: center;
    margin-bottom: 0.5em;

    &__text {
      color: white;
    }
  }

  &__badge-container {
    display: inline-block;
    position: relative;
    width: em(119);
  }
}
