@use "~styles" as *;

.ask-evaluation-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: em(468);
  margin: auto;
  text-align: center;
  height: 100%;
  justify-content: space-between;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -em(24);
  }

  h4 {
    margin: 0 0 em(16);
  }

  &__progress {
    margin-bottom: em(8);
  }

  &__count {
    margin-bottom: em(16);

    @include mdxl {
      margin-bottom: em(24);
    }
  }

  &__process {
    p {
      margin: 0;
    }

    margin-bottom: em(8);
  }

  &__info {
    p {
      margin: 0;
    }

    margin-bottom: em(24);
  }

  &__image {
    position: relative;
    width: em(168);
    height: em(168);
    margin-bottom: em(37);
  }
}
