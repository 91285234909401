@font-face {
  font-family: proxima-nova;
  src: url("/fonts/ProximaNova/ProximaNova-Bold.otf");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: proxima-nova;
  src: url("/fonts/ProximaNova/ProximaNova-Regular.otf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: proxima-nova;
  src: url("/fonts/ProximaNova/ProximaNova-Semibold.otf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: material-rounded;
  src: url("/fonts/Material/MaterialSymbolsRounded.ttf");
  font-weight: 200;
  font-display: swap;
}

// Color Palette
// To regenerate filter when changing hexadecimal : https://codepen.io/sosuke/pen/Pjoqqp
// preprend the result with "brightness(0) saturate(100%)" if the svg was uploaded in white.
$blue-100: #f8f9fa;
$blue-200: #ecf2f2;
$blue-300: #dde7e9;
$blue-300-filter: invert(99%) sepia(52%) saturate(309%) hue-rotate(165deg) brightness(93%) contrast(96%);
$blue-400: #5b7e87;
$blue-500: #002333;
$blue-500-filter: invert(10%) sepia(18%) saturate(5789%) hue-rotate(171deg) brightness(93%) contrast(102%);
$blue-600: #001e28;
$blue-600-filter: invert(10%) sepia(32%) saturate(1408%) hue-rotate(150deg) brightness(103%) contrast(104%);
$blue-cyan: #adebe5;
$blue-cyan-filter: invert(92%) sepia(11%) saturate(902%) hue-rotate(109deg) brightness(103%) contrast(84%);
$red-100: #f68670;
$red-100-filter: invert(87%) sepia(29%) saturate(6259%) hue-rotate(311deg) brightness(97%) contrast(99%);
$red-200: #ab3323;
$red-200-filter: invert(21%) sepia(81%) saturate(1859%) hue-rotate(346deg) brightness(93%) contrast(90%);
$yellow: #ffbc64;
$yellow-filter: invert(81%) sepia(79%) saturate(633%) hue-rotate(312deg) brightness(103%) contrast(101%);
$green-100: #46c1b8;
$green-100-filter: invert(67%) sepia(76%) saturate(341%) hue-rotate(126deg) brightness(88%) contrast(82%);
$green-200: #009788;
$green-200-filter: invert(52%) sepia(65%) saturate(6715%) hue-rotate(153deg) brightness(94%) contrast(101%);
$grey: #c4c4c4;
$grey-60: #999;
$grey-60-filter: invert(64%) sepia(0%) saturate(449%) hue-rotate(259deg) brightness(97%) contrast(82%);
$white: #fff;
$white-filter: invert(100%);
$transparent: transparent;
$facebook-enabled: #1977f2;
$facebook-disabled: #4561a2;
$apple: #303030;
$grey200: #787878;

// Theme
$primary-color: $blue-400;
$secondary-color: $red-100;
$text-color: $blue-600;
$subdued-text-color: $grey200;

// Utilities
$background-color-darker: $blue-500;
$footer-separator: $blue-200;

// Score
$score-lowest: $red-200;
$score-low: $red-100;
$score-medium: $yellow;
$score-high: $green-100;
$score-highest: $green-200;
