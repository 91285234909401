@use "~styles" as *;

.search-bar {
  width: 100%;
  position: relative;

  @include mdxl {
    max-width: 31.5rem;
  }

  &__results {
    position: absolute;
    top: 8rem;
    left: 0;
    display: none;
    transition: max-height 0.2s ease-in-out;
    height: fit-content;
    width: 100%;
    background-color: $white;
    padding: 0;
    border: 1px solid $blue-300;
    box-shadow: 0 3px 16px rgba($blue-300, 0.32);
    color: $text-color;

    @include mdxl {
      top: 3rem;
      height: fit-content;
      margin: 0 em(8);
      width: calc(100% - em(16));
      border-top: none;
    }
  }

  &__header {
    display: none !important;
  }

  &--expanded {
    position: fixed;
    top: 0;
    left: 0;
    background: $white;
    z-index: 10;

    @include sm {
      margin: 0 !important;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .search-bar__results {
      height: calc(100vh - 8em);
      overflow: auto;
    }

    @include mdxl {
      position: relative;
      background: none;

      /* stylelint-disable-next-line selector-class-pattern */
      .search-bar__results {
        height: fit-content;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .search-bar__header {
      display: flex !important;
      background: $white;
      position: relative;

      @include mdxl {
        display: none !important;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .search-bar__icon {
      top: 1.5rem;

      @include mdxl {
        top: 0;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .search-bar__close {
      top: 1.5rem;

      @include mdxl {
        top: 0;
      }
    }
  }

  input {
    width: calc(100% - rem(32));
    height: 3rem;
    border: 1px solid $blue-300;
    padding-left: 3.13rem;
    padding-right: 1rem;
    box-shadow: 0 3px 16px rgba($blue-300, 0.52);
    border-radius: 0.5rem;
    margin: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    @include text-size-xl;

    position: relative;

    &::placeholder {
      color: $grey-60;
      opacity: 1;
    }

    &:hover {
      &::placeholder {
        color: $blue-600;
      }
    }

    &:focus-visible {
      outline: none;

      &::placeholder {
        color: $blue-600;
      }
    }

    @include mdxl {
      width: 100%;
      height: 3rem;
      margin: 0;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &__icon {
    position: absolute;
    top: 0;
    left: 2em;
    height: 100%;
    display: flex;
    align-items: center;

    @include mdxl {
      left: 1em;
      top: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &__close {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 2em;
    top: 0;
    cursor: pointer;
    color: $blue-600;

    @include mdxl {
      right: 1em;
      top: 0;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}
